<template>
  <v-card v-if="carePlanForEditing" rounded="lg" flat color="grey lighten-5" class="flex mb-4 pb-4">
    <v-row class="px-4 pt-3" align="center" no-gutters>
      <span style="font-weight: bold; font-size: 20px; opacity: 0.6">Care Detail </span>
      <v-spacer /><span class="mr-1 text-body-2" v-if="usersDict[carePlanForEditing.lastAuthor]"
        >{{ usersDict[carePlanForEditing.lastAuthor].firstName }}
        {{ usersDict[carePlanForEditing.lastAuthor].lastName }}</span
      >
      <span class="text-body-2"
        >{{ specificRelativeTimeWithHoursAndMinutes(Date.parse(carePlanForEditing.updated)) }}
      </span>
    </v-row>
    <v-row v-if="false" class="py-1" no-gutters>
      <span
        style="font-weight: bold; font-size: 18px"
        v-if="carePlanForEditing.primaryCondition && carePlanForEditing.primaryCondition.length > 0"
        ><span>{{ carePlanForEditing.primaryCondition[0].split('|')[0] }}</span
        ><span class="ml-1">{{ carePlanForEditing.primaryCondition[0].split('|')[1] }}</span></span
      >
    </v-row>

    <v-textarea
      placeholder="Add care information details..."
      rows="1"
      @change="edited = true"
      @blur="saveCarePlan"
      auto-grow
      no-resize
      flat
      solo
      v-model="carePlanForEditing.generalNotes"
      background-color="grey lighten-5"
      hide-details
      class="px-1"
    >
    </v-textarea>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from '@vue/composition-api'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const carePlan = ref(props.patient.rpmCarePlan)
    const edited = ref(false)

    const usersDict = ref(rootStore.state.org.usersDict)

    const carePlanStructure = ref({
      primaryCondition: [],
      goals: '',
      interventions: '',
      assessment: '',
      lastRevised: '',
      lastAuthor: '',
      editLog: [],
    })

    const autoGeneratedCarePlanContent = computed(() => {
      let primaryCondition = null
      if (props.patient.conditions.length > 0) {
        primaryCondition = [props.patient.conditions[0]]
      }
      return {
        primaryCondition: primaryCondition,
        generalNotes: '',
        goals: '',
        interventions: '',
        assessment: '',
        lastRevised: '',
        editLog: [],
      }
    })

    const carePlanForEditing = ref(null)

    const saveCarePlan = async () => {
      if (edited.value) {
        carePlanForEditing.value.updated = new Date().toISOString()
        carePlanForEditing.value.lastAuthor = rootStore.state.auth.user.id
        carePlanForEditing.value.editLog.push({
          author: rootStore.state.auth.user.id,
          revision: carePlanForEditing.value.updated,
        })
        await rootStore.dispatch('patient/updateCarePlan', {
          rpmCarePlan: JSON.stringify(carePlanForEditing.value),
        })
      }
    }

    onMounted(() => {
      if (props.patient.rpm && props.patient.rpm.carePlan) {
        carePlanForEditing.value = JSON.parse(props.patient.rpm.carePlan)
      } else {
        carePlanForEditing.value = JSON.parse(JSON.stringify(autoGeneratedCarePlanContent.value))
      }
    })

    onBeforeUnmount(() => {
      saveCarePlan()
    })

    return {
      carePlan,
      carePlanStructure,
      autoGeneratedCarePlanContent,
      carePlanForEditing,
      saveCarePlan,
      edited,
      specificRelativeTimeWithHoursAndMinutes,
      usersDict,
    }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
