<template>
  <v-container class="px-0 pt-0" fluid>
    <patient-chart-care-plan :patient="patient" />
    <patient-meta-combined-charts :patient="patient" class="mb-10" />
    <v-row no-gutters>
      <v-card flat color="transparent" class="flex">
        <chart-notes :patient="patient" />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ChartNotes from '@/components/ChartNotes.vue'
import PatientChartCarePlan from '@/components/PatientChartCarePlan.vue'
import PatientMetaCombinedCharts from '@/components/metriccharts/PatientCombinedCharts.vue'
import bus from '@/core/helpers/bus'

export default {
  components: { ChartNotes, PatientMetaCombinedCharts, PatientChartCarePlan },
  data() {
    return {
      doneLoading: false,
      showNotesLoading: true,
      patientId: null,
    }
  },
  computed: {
    ...mapState('patient', ['patient', 'deprecatedNotesLoading']),
  },
  watch: {
    deprecatedNotesLoading(val) {
      this.showNotesLoading = !!val
    },
  },
  mounted() {
    if (this.$route.meta.openAlertNote) {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(
        () =>
          bus.$emit('addNoteToPoint', {
            type: 'metric',
            data: this.patient.thresholdBreachAlertNotifications[0].event,
          }),
        400
      )
    }

    if (this.$route.query.open === 'message') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('messenger:open', { patient: this.patient }), 800)
    }

    if (this.$route.query.open === 'note') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('addNote'), 800)
    }

    if (this.$route.query.open === 'video') {
      window.history.replaceState(null, 'NOVO RPM', '/patients/' + this.$route.params.id + '/feed')
      setTimeout(() => bus.$emit('video:open', this.patient), 800)
    }
  },
  beforeDestroy() {},
}
</script>
